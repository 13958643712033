import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnneeScolaire } from 'app/models/annee-scolaire.model';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

    constructor( private _httpClient: HttpClient){
    }
    post(url:string, data:any): Observable<any>{

      return this._httpClient.post(environment.urlApi+url + '?annee_id=' + this.activeAnnee?.id,data);
    }

    put(url:string, data:any): Observable<any>{

      return this._httpClient.put(environment.urlApi+url + '?annee_id=' + this.activeAnnee?.id,data);
    }
    get(url: string): Observable<any>{
      return this._httpClient.get(environment.urlApi+url + '?annee_id=' + this.activeAnnee?.id);
    }
    get2(url:string): Observable<any>{
      return this._httpClient.get(environment.urlApi+url + '?annee_id=' + this.activeAnnee?.id,{responseType: 'blob',reportProgress:true});
    }

    delete(url:string): Observable<any>{
      return this._httpClient.delete(environment.urlApi+url + '?annee_id=' + this.activeAnnee?.id);
    }

    post2(url:string, data:any): Observable<any>{
      console.log(url)
      return this._httpClient.post(environment.urlApi+url + '?annee_id=' + this.activeAnnee?.id,data,{responseType: 'blob',reportProgress:true});
    }

    post3(url:string, data:any,options): Observable<any>{
      console.log(url)
      return this._httpClient.post(environment.urlApi+url + '?annee_id=' + this.activeAnnee?.id, data, options);
    }


    get activeAnnee(): AnneeScolaire {
      if (localStorage.getItem('activeAnnee')) {
       return JSON.parse(
              localStorage.getItem('activeAnnee')
          ) as AnneeScolaire;
      }
  }

}
